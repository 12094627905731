import { useAppDispatch } from '../../utils/typeHelpers'
import { Divider, Grid, List, Image } from 'semantic-ui-react'
import {
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { selectIsCurrentUserScorp } from '../../selectors/user.selectors'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { dollarsToCurrency, formatCurrency } from '../../utils/currencyHelpers'
import { CATCHUP_BOOKKEEPING_PRICE_PER_MONTH } from '../../constants/pricingConstants'
import { makeGridConfig } from '../../components/BaseComponents/Grid'
import UserCancellationFlowFooter from './UserCancellationFlowFooter'
import {
  CANCELLATION_FLOW_STEP,
  useGetCostComparisonPageData,
  useSelfServiceCancellationSteps,
} from './helpers'
import { useEffect } from 'react'
import {
  CancellationStepIdentifier,
  updateAutomaticCancellationAnalyticsRecord,
} from './userCancellation.slice'

const HeardItem = ({
  title,
  price,
  isMonthly,
  suffix = 'value',
}: {
  title: string
  price?: string
  isMonthly?: boolean
  suffix?: string
}) => {
  return (
    <List.Item style={{ padding: '12px 0' }}>
      <List.Icon verticalAlign="middle">
        <Icon
          style={{ display: 'inline' }}
          color="green"
          icon={solid('check')}
        />
      </List.Icon>
      <List.Content>
        <Text as="bodySm">{title}</Text>
        {price && (
          <Text as="bodyXs" color="darkGray">
            {price}/{isMonthly ? 'mo' : 'year'} {suffix}
          </Text>
        )}
      </List.Content>
    </List.Item>
  )
}

const AlternativeItem = ({
  name,
  description,
  price,
  priceSubtext,
  isMonthly,
}: {
  name: string
  description: string
  price: string
  priceSubtext?: string
  isMonthly?: boolean
}) => {
  return (
    <List.Item style={{ padding: '12px 0' }}>
      <List.Content>
        <Grid>
          <Grid.Column width={10}>
            <Text as="h3">{name}</Text>
            <Text as="bodySm">{description}</Text>
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <Text as="h3">
              <b>{price}</b>/{isMonthly ? 'mo' : 'year'}
            </Text>
            <Text as="bodySm" color="darkGray">
              {priceSubtext}
            </Text>
          </Grid.Column>
        </Grid>
      </List.Content>
    </List.Item>
  )
}

const CostComparison = ({ recordId }: { recordId?: number }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const updateRecord = async (recordId?: number) => {
      if (recordId) {
        await updateAutomaticCancellationAnalyticsRecord(recordId, {
          finalStepReached: CancellationStepIdentifier.COST_COMPARISON,
        })(dispatch)
      }
    }
    updateRecord(recordId)
  }, [dispatch, recordId])

  const isScorp = useReselector(selectIsCurrentUserScorp)

  const goToNextStep = useSelfServiceCancellationSteps(
    CANCELLATION_FLOW_STEP.costComparison
  )

  const data = useGetCostComparisonPageData(isScorp)
  return (
    <Grid>
      <GridRowColumn centerContent>
        <Text as="display2">Compare and save with Heard</Text>
      </GridRowColumn>
      <GridRowColumn centerContent {...makeGridConfig([10, 16], true)}>
        <Text as="bodyLg" textAlign="center">
          Paying for services a la carte adds up fast. Heard saves money by
          giving you everything you need all in one place.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} verticalAlign="top">
              <Card backgroundColor="natural">
                <Text as="display2" color="green">
                  Heard
                </Text>
                <Text as="bodyXs" color="darkGray" style={{ marginTop: '5px' }}>
                  {data.subText}
                </Text>
                <Divider style={{ borderWidth: '2px' }} />
                <List>
                  <HeardItem
                    title="Monthly Bookkeeping with experts"
                    price={data.bookkeepingPriceText}
                    isMonthly={data.isMonthly}
                  />
                  {isScorp && (
                    <HeardItem
                      title="Annual business tax preparation"
                      price={data.businessTaxPrepPriceText}
                      isMonthly={data.isMonthly}
                    />
                  )}
                  <HeardItem
                    title="Annual personal tax preparation"
                    price={data.personalTaxPrepPriceText}
                    isMonthly={data.isMonthly}
                  />
                  <HeardItem
                    title="Catch up bookkeeping"
                    price={formatCurrency(
                      data.isMonthly
                        ? CATCHUP_BOOKKEEPING_PRICE_PER_MONTH
                        : dollarsToCurrency(
                            CATCHUP_BOOKKEEPING_PRICE_PER_MONTH
                          ).multiply(12),
                      { hideDecimalsIfZero: true }
                    )}
                    suffix={'one-time fee'}
                    isMonthly={data.isMonthly}
                  />
                  <HeardItem
                    title="Unlimited email support"
                    price={data.cpaSupportPriceText}
                    isMonthly={data.isMonthly}
                  />
                  {isScorp && (
                    <HeardItem
                      title="S Corp election support and compliance"
                      price={data.scorpSupportPriceText}
                      isMonthly={data.isMonthly}
                    />
                  )}
                </List>
                <Text as="bodySm">Plus...</Text>
                <List>
                  <HeardItem title="Personalized quarterly tax estimates" />
                  <HeardItem title="Monthly financial insights" />
                  <HeardItem title="Tools to determine salary and savings allocation" />
                </List>
                <div style={{ flex: 1 }} />
                <Divider style={{ borderWidth: '2px' }} />
                <Grid verticalAlign="bottom">
                  <Grid.Row>
                    <Grid.Column verticalAlign="middle" width={4}>
                      <Image
                        src="https://heard-images.s3.amazonaws.com/assets/piggy-bank-green.svg"
                        style={{
                          height: 80,
                          width: 80,
                          backgroundColor: 'transparent',
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" width={12}>
                      <Text textAlign="right" as="display" color="green">
                        {data.currentSubscriptionPrice}/
                        {data.isMonthly ? 'mo' : 'year'}
                      </Text>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card>
            </Grid.Column>
            <Grid.Column width={8} stretched>
              <Card backgroundColor="natural">
                <Text as="display2" color="green">
                  Alternatives
                </Text>
                <Text as="bodyXs" color="darkGray" style={{ marginTop: '5px' }}>
                  {data.subText}
                </Text>
                <Divider style={{ borderWidth: '2px' }} />
                <List>
                  <AlternativeItem
                    name="Quickbooks"
                    description="Bookkeeping with expert"
                    price={data.bookkeepingPriceText}
                    isMonthly={data.isMonthly}
                  />
                  {isScorp && (
                    <AlternativeItem
                      name="Collective or Bench"
                      description="Annual business tax preparation"
                      price={`+ ${data.businessTaxPrepPriceText}`}
                      priceSubtext={
                        data.isMonthly
                          ? '$1k prorated over 12 months for comparison'
                          : undefined
                      }
                      isMonthly={data.isMonthly}
                    />
                  )}
                  <AlternativeItem
                    name="Bench or WellnessFi"
                    description="Annual personal tax preparation"
                    price={`+ ${data.personalTaxPrepPriceText}`}
                    priceSubtext={
                      data.isMonthly
                        ? '$565 prorated over 12 months for comparison'
                        : undefined
                    }
                    isMonthly={data.isMonthly}
                  />
                  <AlternativeItem
                    name="Bench"
                    description="Catch up bookkeeping"
                    price={`+ ${data.catchupBookkeepingPriceText}`}
                    priceSubtext="One-time fee"
                    isMonthly={data.isMonthly}
                  />
                  <AlternativeItem
                    name="Local CPA"
                    description="Tax support"
                    price={`+ ${data.cpaSupportPriceText}`}
                    isMonthly={data.isMonthly}
                  />
                  {isScorp && (
                    <AlternativeItem
                      name="WellnessFi"
                      description="S Corp election support and compliance"
                      price={`+ ${data.scorpSupportPriceText}`}
                      priceSubtext="$1.5k prorated over 12 months for comparison"
                      isMonthly={data.isMonthly}
                    />
                  )}
                </List>
                <div style={{ flex: 1 }} />
                <Divider style={{ borderWidth: '2px' }} />
                <Grid verticalAlign="bottom">
                  <Grid.Row>
                    <Grid.Column verticalAlign="middle" width={4}>
                      <Image
                        src="https://heard-images.s3.amazonaws.com/assets/piggy-bank-orange.svg"
                        style={{
                          height: 80,
                          width: 80,
                          backgroundColor: 'transparent',
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" width={12}>
                      <Text textAlign="right" as="display" color="green">
                        {data.totalAlternativePriceText}/
                        {data.isMonthly ? 'mo' : 'year'}
                      </Text>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <GridRowColumn />
          <GridRowColumn centerContent>
            <Text as="h1">Did you know?</Text>
          </GridRowColumn>
          <GridRowColumn centerContent {...makeGridConfig([10, 16], true)}>
            <Text as="bodyLg">
              Right now, you have a locked-in rate of{' '}
              {data.currentSubscriptionPrice}/{data.isMonthly ? 'mo' : 'year'}.
              If you decide to re-join later, you will have to pay an additional
              catch up bookkeeping fee in addition to any increase in rate.
            </Text>
          </GridRowColumn>
          <Grid.Row>
            <Grid.Column width={8}>{data.currentRateCard}</Grid.Column>
            <Grid.Column width={8}>{data.newMemberRateCard}</Grid.Column>
          </Grid.Row>
        </Grid>
      </GridRowColumn>
      <UserCancellationFlowFooter
        stayLabel="Keep My Plan"
        goToNextStep={() => goToNextStep(recordId)}
      />
    </Grid>
  )
}

export default CostComparison
