import { combineReducers } from 'redux'
import authCombinedReducer from './auth'

import employeeReducer from '../features/Payroll/employee.slice'
import employeeJobReducer from '../features/Payroll/employeeJob.slice'
import payrollProfileReducer from '../features/Payroll/payrollProfile.slice'
import payScheduleReducer from '../features/Payroll/paySchedule.slice'
import transactionCategoriesReducer from '../features/Reports/reports.slice'
import financialAccountsReducer from './finances/financialAccountsReducer'
import financialInsightsReducer from './finances/financialInsightsReducer'
import transactionFiltersReducer from './finances/transactionFiltersReducer'
import userDocumentsReducer from '../features/UserDocuments/userDocuments.slice'
import userCallEventsReducer from './finances/userCallEventsReducer'
import bookkeepingReportsReducer from './finances/bookkeepingReportsReducer'
import userTaxEstimatesReducer from '../features/Taxes/QuarterlyTaxEstimates/userTaxEstimates.slice'
import allocationGuidesReducer from './finances/allocationGuidesReducer'
import allocationRulesReducer from './finances/allocationRulesReducer'
import financialProfileActionItemsReducer from '../features/Dashboard/financialProfileActionItems.slice'
import annualTaxFilingsReducer from '../features/Taxes/AnnualTaxes/annualTaxFilings.slice'
import annualTaxFilingFormsReducer from '../features/Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import annualTaxFormTypesReducer from '../features/Taxes/AnnualTaxes/annualTaxFormType.slice'
import aboundAnnualTaxFilingsReducer from '../features/Taxes/AnnualTaxes/aboundAnnualTaxFilings.slice'
import transactionsReducer from '../features/Transactions/transactions.slice'
import annualTaxFilingStatusTypesReducer from '../features/Taxes/AnnualTaxes/annualTaxFilingStatusType.slice'
import annualTaxOutcomeReducer from '../features/Taxes/AnnualTaxes/annualTaxOutcome.slice'
import adminReducer from './admin'
import fetchReducer from './fetch'
import conversationsReducer from '../features/Conversations/conversation.slice'
import calendlyLinkReducer from './calendlyLink.slice'
import chartOfAccountsReducer from '../features/ChartOfAccounts/chartOfAccount.slice'
import contractorReducer from '../features/Payroll/contractor.slice'
import payrollCompanyReducer from '../features/Payroll/payrollCompany.slice'
import payrollCompanyLocationReducer from '../features/Payroll/payrollCompanyLocation.slice'
import payrollReducer from '../features/Payroll/payroll.slice'
import userTaxCalculationsReducer from '../features/Admin/UserTaxCalculations/userTaxCalculation.slice'
import quarterlyTaxEstimateDetailsReducer from '../features/Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import annualTaxDetailsReducer from '../features/Admin/AnnualTaxDetails/annualTaxDetails.slice'
import subscriptionReducer from './subscription.slice'
import userImportantDatesReducer from '../features/Dashboard/UserImportantDates/userImportantDates.slice'
import userActionItemsReducer from '../features/Dashboard/UserActionItems/userActionItems.slice'
import sidebarReducer from './sidebar.slice'
import userFocusAreaReducer from '../features/Signup/userFocusArea.slice'
import allEoyReviewStepsReducer from '../features/Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import userEoyReviewProgressReducer from '../features/Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import taxTeamReducer from './taxTeam.slice'
import skyflowReducer from './skyflowSlice'
import flyoutReducer from './flyout.slice'
import plaidItemReducer from './finances/plaidItemReducer'
import journalEntryReducer from './admin/journalEntryReducer'
import taxChecklistQuestionReducer from '../features/Taxes/AnnualTaxes/TaxChecklist/taxChecklistQuestion.slice'
import sCorpStatusReducer from '../features/Dashboard/SCorpUpsell/sCorpStatus.slice'
import journalEntryLineReducer from './admin/journalEntryLineReducer'
import smsConsentReducer from '../features/SmsConsent/smsConsentReducer'
import taxUserDocumentsReducer from '../features/Taxes/AnnualTaxes/taxUserDocuments.slice'
import plaidStatementInstitutionsReducers from './finances/plaidStatementInstitutionsReducers'
import financialAdvisoryProfileReducer from '../features/FinancialAdvisory/financialAdvisory.slice'
import userFinancialAdvisoryCallEventsReducer from '../features/FinancialAdvisory/userFinancialAdvisoryCallEvents.slice'
import catchUpBookkeepingStatusReducer from '../features/CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'

export default combineReducers({
  aboundAnnualTaxFilings: aboundAnnualTaxFilingsReducer,
  admin: adminReducer,
  allEoyReviewSteps: allEoyReviewStepsReducer,
  allocationGuides: allocationGuidesReducer,
  allocationRules: allocationRulesReducer,
  annualTaxDetails: annualTaxDetailsReducer,
  annualTaxFilings: annualTaxFilingsReducer,
  annualTaxFilingForms: annualTaxFilingFormsReducer,
  annualTaxFilingStatusTypes: annualTaxFilingStatusTypesReducer,
  annualTaxOutcomes: annualTaxOutcomeReducer,
  annualTaxFormTypes: annualTaxFormTypesReducer,
  auth: authCombinedReducer,
  bookkeepingReports: bookkeepingReportsReducer,
  calendlyLinks: calendlyLinkReducer,
  catchUpBookkeepingStatus: catchUpBookkeepingStatusReducer,
  chartOfAccounts: chartOfAccountsReducer,
  contractors: contractorReducer,
  conversations: conversationsReducer,
  fetch: fetchReducer,
  flyout: flyoutReducer,
  financialAccounts: financialAccountsReducer,
  financialInsights: financialInsightsReducer,
  financialProfileActionItems: financialProfileActionItemsReducer,
  financialAdvisoryProfile: financialAdvisoryProfileReducer,
  employees: employeeReducer,
  employeeJobs: employeeJobReducer,
  journalEntryLines: journalEntryLineReducer,
  journalEntries: journalEntryReducer,
  payrolls: payrollReducer,
  payrollProfile: payrollProfileReducer,
  paySchedule: payScheduleReducer,
  payrollCompany: payrollCompanyReducer,
  payrollCompanyLocation: payrollCompanyLocationReducer,
  plaidItems: plaidItemReducer,
  plaidStatementInstitutions: plaidStatementInstitutionsReducers,
  quarterlyTaxEstimateDetails: quarterlyTaxEstimateDetailsReducer,
  sCorpStatus: sCorpStatusReducer,
  skyflowTokens: skyflowReducer,
  sidebarState: sidebarReducer,
  smsConsentStatuses: smsConsentReducer,
  subscription: subscriptionReducer,
  taxChecklist: taxChecklistQuestionReducer,
  taxTeam: taxTeamReducer,
  taxUserDocuments: taxUserDocumentsReducer,
  transactions: transactionsReducer,
  transactionCategories: transactionCategoriesReducer,
  transactionFilters: transactionFiltersReducer,
  userActionItems: userActionItemsReducer,
  userCallEvents: userCallEventsReducer,
  userDocuments: userDocumentsReducer,
  userEoyReviewProgress: userEoyReviewProgressReducer,
  userFinancialAdvisoryCallEvents: userFinancialAdvisoryCallEventsReducer,
  userFocusAreas: userFocusAreaReducer,
  userImportantDates: userImportantDatesReducer,
  userTaxCalculations: userTaxCalculationsReducer,
  userTaxEstimates: userTaxEstimatesReducer,
})
