import { useFlag } from '@openfeature/react-sdk'
import { selectCatchupBookkeepingFees } from '../../../selectors/catchupBookkeeping.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  FEATURE_FLAG_KEYS,
  HISTORICAL_BOOKKEEPING_FEE_COHORTS,
  useSetOpenFeatureTargetingKey,
} from '../../OpenFeature'
import { getAnnualTaxFilingForYearSelector } from '../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { isProduction } from '../../../utils/envHelpers'
import {
  prodTrialCatchupBkPriceId,
  testTrialCatchupBkPriceId,
} from '../../../constants/pricingConstants'

// Determines how much a user should pay for the catchup bookkeeping price
export const useCatchupBookkeepingPrice = () => {
  useSetOpenFeatureTargetingKey()

  const cohortFlag = useFlag(
    FEATURE_FLAG_KEYS.catchupBookkeepingFee,
    HISTORICAL_BOOKKEEPING_FEE_COHORTS.cohort2
  )

  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const taxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )

  const includePriorYearTaxes = Boolean(taxFiling?.isLateJoiner)
  const catchupBookkeepingPrice = useReselector(
    selectCatchupBookkeepingFees,
    includePriorYearTaxes
  )

  // We don't want to show the fee if the user has no catchup bookkeeping months
  if (
    !catchupBookkeepingPrice.amount ||
    catchupBookkeepingPrice.amount.value === 0 ||
    cohortFlag.value === HISTORICAL_BOOKKEEPING_FEE_COHORTS.none
  ) {
    return undefined
  }

  return {
    ...catchupBookkeepingPrice,
    freeTrialPriceId: isProduction()
      ? prodTrialCatchupBkPriceId
      : testTrialCatchupBkPriceId,
  }
}
