import React, { useState } from 'react'

// BL
import { ReactClickSignature } from '../shared'
import { formatCurrencyFromCents } from '../../../../utils/currencyHelpers'

// UI
import { Button, Menu, Popup, Step } from 'semantic-ui-react'

interface Props {
  heardBalanceInCents: number
  statementBalanceInCents: number
  onCompleteClicked: () => void
  onNewTransactionClicked: ReactClickSignature
}

const PanelHeader: React.FC<Props> = ({
  heardBalanceInCents,
  statementBalanceInCents,
  onCompleteClicked,
  onNewTransactionClicked,
}) => {
  const [loading, setLoading] = useState(false)
  const differenceInCents = Math.abs(
    statementBalanceInCents - heardBalanceInCents
  )
  const statementBalanceFormatted = formatCurrencyFromCents(
    statementBalanceInCents
  )
  const heardBalanceFormatted = formatCurrencyFromCents(heardBalanceInCents)
  const differenceInDollars = formatCurrencyFromCents(differenceInCents)

  const onCompleteClickedChild = async () => {
    try {
      setLoading(true)
      await onCompleteClicked()
    } catch (err) {
      alert(err)
    } finally {
      setLoading(false)
    }
  }

  const verbPredicate =
    differenceInCents === 0
      ? 'equal'
      : statementBalanceInCents > heardBalanceInCents
        ? 'under by'
        : 'over by'

  return (
    <Menu borderless className="mute reconciliation-header" fluid widths={3}>
      <Menu.Item>
        <Step.Content>
          <Step.Title as="h4">Statement Balance</Step.Title>
          <Step.Content as="h3">{statementBalanceFormatted}</Step.Content>
        </Step.Content>
      </Menu.Item>
      <Menu.Item>
        <Step.Content>
          <Step.Title as="h4">We are {verbPredicate}</Step.Title>
          {differenceInCents !== 0 && (
            <Step.Content as="h1">{differenceInDollars}</Step.Content>
          )}
          {differenceInCents === 0 && (
            <Button
              loading={loading}
              disabled={loading}
              color="blue"
              onClick={onCompleteClickedChild}
            >
              Mark reconciliation complete
            </Button>
          )}
        </Step.Content>
      </Menu.Item>
      <Menu.Item>
        <Step.Content style={{ float: 'left' }}>
          <Step.Title as="h4">Heard Balance</Step.Title>
          <Step.Content as="h3">{heardBalanceFormatted}</Step.Content>
        </Step.Content>
        <Popup
          content={'Create a new transaction'}
          inverted
          position="top center"
          trigger={
            <Button
              className="mute create-transaction-button"
              icon="add"
              onClick={onNewTransactionClicked}
            />
          }
        />
      </Menu.Item>
    </Menu>
  )
}

export default PanelHeader
