import { CSSProperties, FC } from 'react'
import { Card as SemanticCard, StrictCardProps } from 'semantic-ui-react'
import styled from 'styled-components'
import { omit } from 'lodash'

import { Colors } from '../../styles/theme'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

interface Props extends Omit<StrictCardProps, 'fluid'> {
  backgroundColor?: keyof typeof Colors
  fullWidth?: boolean
  padding?: number | string
  style?: CSSProperties
  type?: 'body' | 'section' | 'subsection'
  variant?: 'regular' | 'disabled' | 'emphasis' | 'raised' | 'noShadow'
  // When cards are in a mobile view they often should be flush with the sides rather than have the standard padding of the container
  // This is that offset (should be negative)
  unsetMobileMargin?: number
}

const Content = styled(SemanticCard.Content)({ '&&&&': { padding: 0 } })

interface CardComponent extends FC<Props> {
  Content: typeof Content
  Description: typeof SemanticCard.Description
  Group: typeof SemanticCard.Group
  Header: typeof SemanticCard.Header
  Meta: typeof SemanticCard.Meta
}

const Card: CardComponent = styled((props: Props) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <SemanticCard
      {...omit(props, 'backgroundColor', 'fullWidth', 'type')}
      className={`${props.className} ${isMobile && 'mobile'}`}
    />
  )
})(
  ({
    backgroundColor,
    fullWidth = false,
    padding,
    type = 'body',
    variant = 'regular',
    unsetMobileMargin,
  }) => ({
    // this is a CSS trick to force higher precedence for the generated classes from
    // styled-components over the current styles we have in our SCSS files
    '&&&': {
      backgroundColor: backgroundColor
        ? Colors[backgroundColor]
        : variant === 'emphasis'
          ? Colors.natural
          : type === 'subsection'
            ? Colors.stone
            : Colors.white,
      border:
        variant === 'emphasis'
          ? `1px solid ${Colors.natural}`
          : type === 'section'
            ? `1px solid ${Colors.lightGray}`
            : 'none',
      borderRadius: `${type === 'subsection' ? '4px' : '8px'} !important`,
      boxShadow:
        variant === 'noShadow'
          ? 'none'
          : variant === 'raised'
            ? '0px 4px 12px 0px rgba(0, 0, 0, 0.15)'
            : type === 'body'
              ? '2px 2px 4px 0px rgba(32, 78, 81, 0.06)'
              : 'none',
      color:
        variant === 'disabled'
          ? `${Colors.mediumGray} !important`
          : Colors.black,
      opacity: variant === 'disabled' ? '60%' : '100%',
      padding: padding || (type === 'subsection' ? '24px' : '32px'),
      width: fullWidth ? '100%' : 'initial',

      ...(unsetMobileMargin && {
        '&.mobile': {
          marginLeft: unsetMobileMargin,
          marginRight: unsetMobileMargin,
          maxWidth: 'unset',
          width: 'unset',
        },
      }),
    },
  })
)

Card.Content = Content
Card.Description = SemanticCard.Description
Card.Group = SemanticCard.Group
Card.Header = SemanticCard.Header
Card.Meta = SemanticCard.Meta

export default Card
