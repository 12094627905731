import { useMemo } from 'react'

import { GridRowColumn, Text } from '../../../../../components/BaseComponents'
import { Grid } from 'semantic-ui-react'
import DetailsAccordion from './DetailsAccordion'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import { getProductByInterval } from '../../helpers'
import { StripeInterval } from '../../../../../reducers/subscription.slice'
import {
  centsToCurrency,
  formatCurrency,
  formatCurrencyFromCents,
} from '../../../../../utils/currencyHelpers'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectProductDescription } from '../../../../../selectors/user.selectors'
import { StripeProduct } from '../../../../../actions/settings/billingActions'
import { useCatchupBookkeepingPrice } from '../../hooks'

const PlanDetailsSection = ({
  stripeProducts,
  selectedProduct,
  freeTrialEnabled,
  basicPlanEnabled,
  isLateJoiner,
}: {
  stripeProducts: StripeProduct[]
  selectedProduct?: StripeProduct
  freeTrialEnabled: boolean
  basicPlanEnabled: boolean
  isLateJoiner: boolean | null | undefined
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const annualPlan = getProductByInterval(stripeProducts, StripeInterval.year)
  const monthlyPlan = getProductByInterval(stripeProducts, StripeInterval.month)
  const isAnnual = selectedProduct === annualPlan
  const catchupBookkeepingPrice = useCatchupBookkeepingPrice()

  const monthlyDollarsFormatted = useMemo(
    () =>
      selectedProduct?.default_price && isAnnual
        ? formatCurrency(
            centsToCurrency(selectedProduct.default_price.unit_amount).divide(
              12
            ),
            { hideDecimalsIfZero: true }
          )
        : formatCurrencyFromCents(selectedProduct?.default_price.unit_amount, {
            hideDecimalsIfZero: true,
          }),
    [isAnnual, selectedProduct?.default_price]
  )

  const struckMonthlyDollarsFormatted = useMemo(
    () =>
      monthlyPlan?.default_price &&
      formatCurrencyFromCents(monthlyPlan.default_price.unit_amount, {
        hideDecimalsIfZero: true,
      }),
    [monthlyPlan?.default_price]
  )

  // If they have historical bk, bookkeeping section is displayed separately
  const sections = !catchupBookkeepingPrice
    ? [
        'taxes' as const,
        'bookkeeping' as const,
        'budget' as const,
        'scorp' as const,
      ]
    : ['taxes' as const, 'budget' as const, 'scorp' as const]

  const productDescription = useReselector(
    selectProductDescription,
    basicPlanEnabled,
    sections
  )

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Text color={'darkGray'} as={'eyebrow'}>
            {isAnnual
              ? 'annual plan'
              : isLateJoiner
                ? 'annual plan, paid monthly'
                : 'monthly plan'}
          </Text>
        </Grid.Column>
        <Grid.Column width={8}>
          <Text color={'darkGray'} as={'eyebrow'} textAlign="right">
            {!isAnnual && !isLateJoiner && 'cancel anytime'}
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={9}>
          <Text as={isMobile ? 'h2' : 'h1'}>{selectedProduct?.name}</Text>
        </Grid.Column>
        <Grid.Column textAlign="right" width={7}>
          <Text as={isMobile ? 'h2' : 'h1'} style={{ display: 'inline' }}>
            {monthlyDollarsFormatted}
          </Text>
          <Text as={isMobile ? 'h3' : 'h2'} style={{ display: 'inline' }}>
            /mo
          </Text>
          {isAnnual && (
            <div>
              <Text
                color="darkGray"
                style={{
                  textDecoration: freeTrialEnabled ? 'none' : 'line-through',
                  position: 'absolute',
                  right: 15,
                }}
              >
                {freeTrialEnabled
                  ? 'Free Trial'
                  : `${struckMonthlyDollarsFormatted}/mo`}
              </Text>
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn className="short">
        <DetailsAccordion productDescription={productDescription} />
      </GridRowColumn>
    </Grid>
  )
}

export default PlanDetailsSection
