import axios from 'axios'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchWrapper } from '../../reducers/fetch'

export enum CatchUpBookkeepingStatusType {
  incomplete = 'incomplete',
  complete = 'complete',
}
export enum CatchUpBookkeepingStatusActor {
  admin = 'admin',
  system = 'system',
}
export interface CatchUpBookkeepingStatus {
  id: number
  userId: number
  updatedByUserId: number | null
  status: CatchUpBookkeepingStatusType
  actor: CatchUpBookkeepingStatusActor
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export interface CatchUpBookkeepingStatusState {
  [key: number]: CatchUpBookkeepingStatus
}

export const FETCH_USER_CATCH_UP_BOOKKEEPING_STATUS =
  'FETCH_USER_CATCH_UP_BOOKKEEPING_STATUS'

const catchUpBookkeepingStatusSlice = createSlice({
  name: 'catchUpBookkeepingStatus',
  initialState: {} as CatchUpBookkeepingStatusState,
  reducers: {
    receiveCatchUpBookkeepingStatus: (
      state,
      action: PayloadAction<CatchUpBookkeepingStatus>
    ) => {
      state[action.payload.userId] = action.payload
    },
  },
})

export default catchUpBookkeepingStatusSlice.reducer

export const { receiveCatchUpBookkeepingStatus } =
  catchUpBookkeepingStatusSlice.actions

export const fetchUserCatchUpBookkeepingStatus = () => {
  return fetchWrapper({
    fetchKey: FETCH_USER_CATCH_UP_BOOKKEEPING_STATUS,
    defaultErrorMessage: 'There was an error fetching user bookkeeping status.',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<CatchUpBookkeepingStatus>(
        '/finances/api/v2/catch_up_bookkeeping_status'
      )

      dispatch(receiveCatchUpBookkeepingStatus(json.data))
      return json.data
    },
  })
}

export const adminUpdateUserCatchUpBookkeepingStatus = (
  userId: number,
  status: CatchUpBookkeepingStatusType
) => {
  return fetchWrapper({
    defaultErrorMessage: 'Error updating user bookkeeping status',
    fetchFunction: async (dispatch) => {
      const json = await axios.patch<CatchUpBookkeepingStatus>(
        `/finances/api/v2/admin/catch_up_bookkeeping_status/${userId}`,
        { status }
      )

      dispatch(receiveCatchUpBookkeepingStatus(json.data))
      return json.data
    },
  })
}
