import currency from 'currency.js'
import { DateTime } from 'luxon'
import { createSelector } from 'reselect'
import {
  prodCatchupBkPriceId,
  testCatchupBkPriceId,
  CATCHUP_BOOKKEEPING_PRICE_PER_MONTH,
} from '../constants/pricingConstants'
import { selectCurrentAnnualTaxYear } from '../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getFinancialProfile } from './user.selectors'
import { isoToUTCDateTime } from '../utils/dateHelpers'

export const selectApproximatePracticeStartDate = createSelector(
  getFinancialProfile,
  (fp) => {
    if (!fp?.approximatePracticeStartDate) {
      return null
    }
    const dateObject = isoToUTCDateTime(fp?.approximatePracticeStartDate)
    if (!dateObject.isValid) {
      return null
    }
    return dateObject
  }
)

export const selectCatchupBookkeepingMonths = createSelector(
  selectApproximatePracticeStartDate,
  selectCurrentAnnualTaxYear,
  (_: unknown, withPriorYearTaxes: boolean) => withPriorYearTaxes,
  (approximatePracticeStartDate, currentTaxYear, withPriorYearTaxes) => {
    const NOW = DateTime.now()
    let taxMonths = 0
    // calculate the number of months to charge for prior year taxes
    if (withPriorYearTaxes && approximatePracticeStartDate?.year !== NOW.year) {
      // taxes on signup is only relevant for late joiners, so "currentTaxYear" is the prior tax year
      const priorTaxYear =
        currentTaxYear === NOW.year.toString()
          ? Number(currentTaxYear) - 1
          : Number(currentTaxYear)
      // if practice started before the prior tax year, charge for the full year
      if (
        !approximatePracticeStartDate ||
        approximatePracticeStartDate.year < priorTaxYear
      ) {
        taxMonths = 12
      } else {
        // if practice started during the prior tax year, charge for the remaining months
        taxMonths = 12 - approximatePracticeStartDate.month + 1
      }
    }

    if (NOW.month === 1) {
      return {
        monthsThisYear: 0, // no catchup bookkeeping for Jan
        monthsLastYear: taxMonths,
        totalMonths: taxMonths,
      }
    }
    // Allow 4 day grace period each month
    const graceMonth = NOW.day < 5 ? 1 : 0
    // if practice started before this year, only count months from the start of this year
    const calculateFromDate =
      approximatePracticeStartDate?.year === NOW.year
        ? approximatePracticeStartDate
        : NOW.startOf('year')
    const monthsThisYear = Math.max(
      NOW.month - calculateFromDate.month - graceMonth,
      0
    )

    return {
      monthsThisYear,
      monthsLastYear: taxMonths,
      totalMonths: monthsThisYear + taxMonths,
    }
  }
)

export const selectCatchupBookkeepingFees = createSelector(
  selectCatchupBookkeepingMonths,
  (monthsToCharge) => {
    const isProd = process.env.VITE_IS_PROD === 'true'
    return {
      priceId: isProd ? prodCatchupBkPriceId : testCatchupBkPriceId,
      costPerMonth: CATCHUP_BOOKKEEPING_PRICE_PER_MONTH,
      monthCount: monthsToCharge.totalMonths,
      amount: currency(CATCHUP_BOOKKEEPING_PRICE_PER_MONTH).multiply(
        monthsToCharge.totalMonths
      ),
    }
  }
)
