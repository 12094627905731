import { Grid } from 'semantic-ui-react'

import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import { formatCurrency } from '../../../../../utils/currencyHelpers'
import { useCatchupBookkeepingPrice } from '../../hooks'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectProductDescription } from '../../../../../selectors/user.selectors'
import {
  Card,
  GridRowColumn,
  Popup,
  Text,
} from '../../../../../components/BaseComponents'
import DetailsAccordion from './DetailsAccordion'
import { DateTime } from 'luxon'
import currency from 'currency.js'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getAnnualTaxFilingForYearSelector } from '../../../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import {
  selectCatchupBookkeepingMonths,
  selectApproximatePracticeStartDate,
} from '../../../../../selectors/catchupBookkeeping.selectors'
import { useMemo } from 'react'

const CatchupBookkeepingSection = () => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const catchupBookkeepingPrice = useCatchupBookkeepingPrice()
  const productDescription = useReselector(selectProductDescription, false, [
    'bookkeeping' as const,
  ])

  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const taxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )
  const approximatePracticeStartDate = useReselector(
    selectApproximatePracticeStartDate
  )

  const includePriorYearTaxes = Boolean(taxFiling?.isLateJoiner)
  const { monthsThisYear: monthsSoFar, monthsLastYear } = useReselector(
    selectCatchupBookkeepingMonths,
    includePriorYearTaxes
  )

  const currentYear = DateTime.now().year
  const lastYear = currentYear - 1

  const additionalTooltipTextNoTaxes = useMemo(() => {
    if (approximatePracticeStartDate?.year === currentYear) {
      return `Since you started your practice in ${approximatePracticeStartDate.toFormat('MMMM')} of this year, we need to perform Catch-up Bookkeeping for ${catchupBookkeepingPrice?.monthCount} ${catchupBookkeepingPrice?.monthCount === 1 ? 'month' : 'months'}.`
    }
    return `Since you started your practice ${approximatePracticeStartDate?.year ? `in ${approximatePracticeStartDate.year}` : 'before this year'}, we need to perform Catch-up Bookkeeping for all of ${currentYear}.`
  }, [
    approximatePracticeStartDate,
    currentYear,
    catchupBookkeepingPrice?.monthCount,
  ])

  const additionalTooltipTextWithTaxes = useMemo(() => {
    if (approximatePracticeStartDate?.year === Number(currentTaxYear)) {
      return `Since you started your practice in ${approximatePracticeStartDate.year}, and are also having us help you with your taxes for ${currentTaxYear}, we need to perform Catch-up Bookkeeping for all of ${currentYear}, and the months in ${currentTaxYear} starting with ${approximatePracticeStartDate.toFormat('MMMM')}.`
    }
    return `Since you started your practice ${approximatePracticeStartDate?.year ? `in ${approximatePracticeStartDate.year}` : `before ${currentTaxYear}`}, and are also having us help you with your taxes for ${currentTaxYear}, we need to perform Catch-up Bookkeeping for all of ${currentTaxYear} and all of ${currentYear}.`
  }, [approximatePracticeStartDate, currentYear, currentTaxYear])

  if (
    !catchupBookkeepingPrice ||
    (!includePriorYearTaxes && monthsSoFar === 0)
  ) {
    return null
  }

  const pricePerMonth = currency(catchupBookkeepingPrice.costPerMonth)
  const costForThisYear = pricePerMonth.multiply(monthsSoFar)

  return (
    <GridRowColumn>
      <Grid>
        <Grid.Row>
          <Grid.Column width={9}>
            <Text as={isMobile ? 'h2' : 'h1'}>Catch-up Bookkeeping</Text>
          </Grid.Column>
          <Grid.Column textAlign="right" width={7}>
            <Text as={isMobile ? 'h2' : 'h1'} style={{ display: 'inline' }}>
              {formatCurrency(catchupBookkeepingPrice.amount, {
                hideDecimalsIfZero: true,
              })}
            </Text>
            <Text as="bodySm" color="darkGray">
              One time fee
            </Text>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn short>
          <Card type="subsection" backgroundColor="natural">
            {monthsSoFar !== 0 && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text as="bodyLg" color="forest">
                  {monthsSoFar} months at{' '}
                  {formatCurrency(pricePerMonth, {
                    hideDecimalsIfZero: true,
                  })}
                  /mo of catch-up bookkeeping for {currentYear} taxes
                  <Popup
                    content={
                      <Grid>
                        <GridRowColumn>
                          <Text>
                            In order to ensure that we have the most accurate
                            picture of your finances, a Heard bookkeeper will
                            review, categorize and validate all your previous
                            transactions for accuracy.
                          </Text>
                        </GridRowColumn>
                        <GridRowColumn>
                          <Text>{additionalTooltipTextNoTaxes}</Text>
                        </GridRowColumn>
                      </Grid>
                    }
                  />
                </Text>
                <Text as="bodyLg" color="forest">
                  {formatCurrency(costForThisYear, {
                    hideDecimalsIfZero: true,
                  })}
                </Text>
              </div>
            )}
            {includePriorYearTaxes && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text as="bodyLg" color="forest">
                  {monthsLastYear} months at{' '}
                  {formatCurrency(pricePerMonth, {
                    hideDecimalsIfZero: true,
                  })}
                  /mo of catch-up bookkeeping for {lastYear} taxes
                  <Popup
                    content={
                      <Grid>
                        <GridRowColumn>
                          <Text>
                            `The first step in preparing your ${lastYear} taxes
                            is a review of your books. A Heard bookkeeper will
                            review, categorize and validate all your ${lastYear}{' '}
                            transactions for accuracy. This ensures that Heard
                            finds all of the deductions that you qualify for.`
                          </Text>
                        </GridRowColumn>
                        <GridRowColumn>
                          <Text>{additionalTooltipTextWithTaxes}</Text>
                        </GridRowColumn>
                      </Grid>
                    }
                  />
                </Text>
                <Text as="bodyLg" color="forest">
                  {formatCurrency(pricePerMonth.multiply(monthsLastYear), {
                    hideDecimalsIfZero: true,
                  })}
                </Text>
              </div>
            )}
          </Card>
        </GridRowColumn>
        <GridRowColumn className="short">
          <DetailsAccordion productDescription={productDescription} hideTitle />
        </GridRowColumn>
      </Grid>
    </GridRowColumn>
  )
}
export default CatchupBookkeepingSection
