import axios from 'axios'
import { fetchWrapper } from '../../reducers/fetch'

export enum StripeCancellationReasons {
  MISSING_FEATURES = 'missing_features',
  CUSTOMER_SERVICE = 'customer_service',
  OTHER = 'other',
  LOW_QUALITY = 'low_quality',
  SWITCHED_SERVICE = 'switched_service',
  TOO_COMPLEX = 'too_complex',
  TOO_EXPENSIVE = 'too_expensive',
  UNUSED = 'unused',
}

export enum CancellationStepIdentifier {
  SAVING_TIME_AND_MONEY = 'saving_time_and_money',
  FINANCIAL_ACCOUNT_UPDATE_CLICKED = 'financial_account_update_clicked',
  COST_COMPARISON = 'cost_comparison',
  DISCOUNT = 'discount',
  CANCELLATION_COMPLETE = 'cancellation_complete',
}

export enum FaAvailableConnectionType {
  PLAID_STATEMENTS = 'plaid_statements',
  LBA = 'lba',
  CREATE_NEW_ACCOUNT = 'create_new_account',
}

interface AdditionalDetails {
  hasPersonalizedSavingsPage?: boolean
  financialAccountConnectionAvailable?: FaAvailableConnectionType
}

interface AutomaticCancellationAnalyticsRecord {
  id: number
  userId: number
  membershipId: number
  cancellationReason?: string
  cancellationComment?: string
  finalStepReached?: string
  taxEntityType?: string
  subscriptionType?: string
  discountApplied?: boolean
  additionalDetails: AdditionalDetails
  createdAt: string
  updatedAt: string
}

export const CREATE_AUTOMATIC_CANCELLATION_ANALYTICS_RECORD_KEY =
  'CREATE_AUTOMATIC_CANCELLATION_ANALYTICS_RECORD_KEY'
export const createAutomaticCancellationAnalyticsRecord = (
  data: Pick<
    AutomaticCancellationAnalyticsRecord,
    'finalStepReached' | 'additionalDetails'
  >
) =>
  fetchWrapper({
    fetchKey: CREATE_AUTOMATIC_CANCELLATION_ANALYTICS_RECORD_KEY,
    fetchFunction: async () => {
      const res = await axios.post<AutomaticCancellationAnalyticsRecord>(
        '/finances/api/v1/user_cancellation/cancellation_record',
        data
      )
      return res.data
    },
  })

export const UPDATE_AUTOMATIC_CANCELLATION_ANALYTICS_RECORD_KEY =
  'UPDATE_AUTOMATIC_CANCELLATION_ANALYTICS_RECORD_KEY'
export const updateAutomaticCancellationAnalyticsRecord = (
  recordId: number,
  data: Pick<
    AutomaticCancellationAnalyticsRecord,
    'finalStepReached' | 'cancellationComment' | 'cancellationReason'
  >
) =>
  fetchWrapper({
    fetchKey: UPDATE_AUTOMATIC_CANCELLATION_ANALYTICS_RECORD_KEY,
    fetchFunction: async () => {
      const res = await axios.put(
        `/finances/api/v1/user_cancellation/cancellation_record/${recordId}`,
        data
      )
      return res.status === 200
    },
  })

export const FETCH_TOTAL_COMPLETED_RECONCILIATION_COUNT =
  'FETCH_TOTAL_COMPLETED_RECONCILIATION_COUNT'
export const fetchTotalCompletedReconciliationCount = () =>
  fetchWrapper({
    fetchKey: FETCH_TOTAL_COMPLETED_RECONCILIATION_COUNT,
    fetchFunction: async () => {
      const res = await axios.get<{ count: number }>(
        '/finances/api/v1/user_cancellation/total_completed_reconciliation_count'
      )
      return res.data.count
    },
  })
