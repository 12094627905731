/**
 * This Panel shows when a User has done nothing related to Advisory
 *
 */

import { Grid, Image, Icon, Loader } from 'semantic-ui-react'
import {
  GridRowColumn,
  Pill,
  Button,
  Text,
  Link,
  Card,
  Modal,
  Alert,
} from '../../../components/BaseComponents'
import CalendlyPopupModal from '../../../components/shared/CalendlyPopupModal'
import { useEffect, useState } from 'react'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { createIntroCallScheduledAfterEffects } from '../service'
import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'
import { AdvisorHelpSummaryCard } from './PreIntroCallPanel'
import {
  getAdvisoryCallPricing,
  selectFinancialAdvisoryProfile,
} from '../financialAdvisory.selectors'
import { useDispatch } from 'react-redux'
import { fetchBetaStatus } from '../userFinancialAdvisoryCallEvents.slice'
import {
  FinancialAdvisoryProfilesStatus,
  putFinancialAdvisoryProfile,
} from '../financialAdvisory.slice'
import { DateTime } from 'luxon'
const INTRO_CALL_CALENDLY_URL =
  'https://calendly.com/d/cmjd-h62-zbx/heard-intro-advisory-call'

export const UpriseIntroCard = () => {
  return (
    <Card type="subsection" backgroundColor="stone40">
      <Grid stackable doubling centered>
        <Grid.Row>
          <Grid.Column textAlign="center" width={12}>
            <Text as="h2">Who will I be working with?</Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12} textAlign="center">
            <Text>
              Heard offers financial advisory in partnership with Uprise, a team
              of Certified Financial Planners that build and create customized
              financial plans based on your goals.
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center">
          <Grid.Column
            width={12}
            textAlign="center"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/uprise_team.svg"
              width="75"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            width={12}
            textAlign="center"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/uprise.svg"
              width="150"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const JoinedWaitlistModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  return (
    <Modal size={'small'} dimmer={'inverted'} open={open} onClose={onClose}>
      <Modal.Header>Got it, you’ve been added to the waitlist!</Modal.Header>
      <Modal.Content>
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}
        >
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/check.svg"
            alt="check"
          />
        </div>
        <Text as="bodyMd">
          We are so glad you&#39;re interested in Financial Advisory. We have
          added your name to our waitlist, and we will notify you when spaces
          open up. Thank you for your understanding!
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const WaitlistCard = ({
  waitlistJoined,
}: {
  waitlistJoined: boolean
}) => {
  if (waitlistJoined) {
    return (
      <Card type="subsection" backgroundColor="moss">
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column width={16}>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Pill color="green">Currently Waitlisted</Pill>
                <Text as="h2">You’re on our waitlist!</Text>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Text as="bodyMd">
                Thank you for letting us know of your interest. You are now on
                the waitlist, and we will let you know when spaces open up.
              </Text>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    )
  }
  return (
    <Card type="subsection" backgroundColor="moss">
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column width={16}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Pill color="teal">Now Waitlist Only</Pill>
              <Text as="h2">
                Our Introductory Calls Are Fully Booked Right Now
              </Text>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Text as="bodyMd">
              Due to high demand, all available slots for our free 20-minute
              introductory calls have been booked. Please join our waitlist by
              clicking &#39;Join Waitlist&#39; below, and we will let you know
              as spaces open up.
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const IntroCallCTAPanel = () => {
  const dispatch = useDispatch()
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()

  /** Key Variables */
  const currentUser = useReselector(getCurrentUser)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const callPricing = useReselector(getAdvisoryCallPricing)
  const faProfile = useReselector(selectFinancialAdvisoryProfile)

  /** State Vars */
  const [betaStatusData, setBetaStatusData] = useState<{
    count?: number
    betaStatus?: string
  }>({})
  const [calendlyOpen, setCalendlyOpen] = useState(false)
  const [fetching, setFetching] = useState(false)

  /** Waitlist Vars */
  const isWaitlist = betaStatusData?.betaStatus === 'waitlist'
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [waitlistJoined, setWaitlistJoined] = useState(
    Boolean(faProfile?.waitlistedAt)
  )
  const [waitlistJoinedModalOpen, setWaitlistJoinedModalOpen] = useState(false)

  // Used to manage after effects of scheduling the intro call
  const introCallScheduledAfterEffects = async (
    eventUri: string | undefined
  ) => {
    track('scheduled intro advisory call', {
      user_id: currentUser?.id || null,
      event_uri: eventUri || null,
    })
    await createIntroCallScheduledAfterEffects({ currentUser })
    // Reload to properly view changes
    location.reload()
  }

  useEffect(() => {
    pageView('advisory: view intro call CTA page')
  }, [pageView])

  useEffect(() => {
    async function fetchData() {
      setFetching(true)
      const betaStatusData = await dispatch(fetchBetaStatus())
      setBetaStatusData(betaStatusData)
      setFetching(false)
    }
    fetchData()
  }, [dispatch, setFetching])

  const updateWaitlist = async () => {
    setLoading(true)
    setError('')

    try {
      track('clicked advisory join waitlist', {
        user_id: currentUser?.id || null,
      })
      if (!faProfile) {
        setError('Something went wrong! Please refresh and try again.')
        return
      }
      const response = await putFinancialAdvisoryProfile(faProfile?.id, {
        waitlistedAt: DateTime.now().toISO(),
        status: FinancialAdvisoryProfilesStatus.waitlisted,
      })(dispatch)
      if (!response) {
        setError('Something went wrong! Please refresh and try again.')
        return
      }
      setWaitlistJoinedModalOpen(true)
      setWaitlistJoined(true)
    } catch {
      setError('An unexpected error occurred. Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  const waitlistButton = () => {
    if (waitlistJoined) {
      return (
        <Button disabled variant="secondary" fullWidth={isMobile}>
          Waitlist Joined
        </Button>
      )
    }
    return (
      <Button
        variant="secondary"
        fullWidth={isMobile}
        loading={loading}
        onClick={() => updateWaitlist()}
      >
        Join Waitlist
      </Button>
    )
  }
  if (fetching) {
    return <Loader active inline />
  }
  return (
    <>
      <Grid
        className="statementTitle"
        style={{ margin: '0 5px' }}
        stackable
        doubling
      >
        {isWaitlist && (
          <GridRowColumn>
            <WaitlistCard waitlistJoined={waitlistJoined} />
          </GridRowColumn>
        )}
        {error && (
          <Alert type="error">
            It looks like we encountered an error! Please refresh the page and
            try again.
          </Alert>
        )}
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={6} textAlign="center">
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/financial_advice.svg"
              alt="heard financial advisory"
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <Grid style={{ margin: '0 5px' }}>
              <GridRowColumn>
                <Pill>Annual Plan Benefit Only</Pill>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="h1">
                  Small business financial planning for therapists like you
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  Heard has partnered with{' '}
                  <Link newPage href="https://uprise.us/" as="secondaryLink">
                    Uprise
                  </Link>
                  , a premium financial advisory platform, to connect our
                  therapists with financial experts geared towards mental health
                  small business owners like you.
                </Text>
              </GridRowColumn>

              <GridRowColumn>
                <Text>
                  <b>Included in your Heard annual membership</b>
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  <Icon name="check" size="small" />
                  20–min introductory consultation with a Certified Financial
                  Planner
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Personalized comprehensive financial plan
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Unlimited chat with your advisor to keep your plan updated
                </Text>
              </GridRowColumn>

              <GridRowColumn>
                <Text>
                  <b>For additional support, add-on</b>
                  <br />
                  <Text>
                    <Icon name="plus" size="small" />
                    45 minute follow-up video calls at ${callPricing}
                  </Text>
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                {!isWaitlist && (
                  <Button
                    fullWidth={isMobile}
                    onClick={() => {
                      setCalendlyOpen(true)
                      track('clicked schedule intro call button', {
                        financial_advisory_step:
                          'start schedule introductary call',
                      })
                    }}
                  >
                    Schedule Intro Call
                  </Button>
                )}

                {isWaitlist && waitlistButton()}
              </GridRowColumn>
              {calendlyOpen && (
                <CalendlyPopupModal
                  url={INTRO_CALL_CALENDLY_URL}
                  open={calendlyOpen}
                  onClose={() => setCalendlyOpen(false)}
                  onScheduled={(eventUri: string | undefined) => {
                    introCallScheduledAfterEffects(eventUri)
                    track('scheduled introductory financial advisory', {
                      financial_advisory_step:
                        'completed schedule introductory call',
                    })
                  }}
                />
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <AdvisorHelpSummaryCard />
      <br />
      <UpriseIntroCard />
      <br />
      <br />
      <br />
      <JoinedWaitlistModal
        open={waitlistJoinedModalOpen}
        onClose={() => setWaitlistJoinedModalOpen(false)}
      />
    </>
  )
}
export default IntroCallCTAPanel
