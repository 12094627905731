import {
  useRef,
  useCallback,
  useEffect,
  useState,
  useSyncExternalStore,
} from 'react'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { debounce, isEqual } from 'lodash'
import { DateTime } from 'luxon'
import {
  FlagQuery,
  FlagValue,
  JsonValue,
  useFlag,
} from '@openfeature/react-sdk'

// We need the base useSelector in here for helpers so it's fine to import
// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux'

import {
  setSidebarState as setReduxSidebarState,
  toggleCategory as toggleCategoryOpenClose,
  toggleSidebar as toggleSidebarOpenClose,
  setScrollYState as setReduxSidebarScrollYState,
  getDefaultSidebarState,
  SidebarConfigProps,
} from '../reducers/sidebar.slice'
import { getSidebarState } from '../selectors/sidebarSelectors'
import { Dispatch, GetState, ReduxState, useAppDispatch } from './typeHelpers'
import { getCurrentUser } from '../selectors/user.selectors'
import { selectFlyoutState } from '../selectors/selectFlyout'
import { clearFlyoutContent, setFlyoutContent } from '../reducers/flyout.slice'
import { useAnalyticsTrack } from '../features/Amplitude'
import {
  FinancialInsightsButtonType,
  INSIGHTS_TYPE,
  insightsCopyConstants,
} from '../components/Finances/shared/utils'

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

// This works like a Component's componentDidMount.
// Essentially it skips any component changes and only will run when the component is first mounted
// It should rarely be used because it breaks the rules/concept of hooks
export const useMountEffect = (fn: () => void) => {
  useEffect(() => {
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

// Creates a timeout ref that will automatically cancel if the component is unmounted
export const useTimeoutRef = () => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return timeoutRef
}

export const useIntervalRef = () => {
  const intervalRef = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    const currentInterval = intervalRef.current
    return () => {
      if (currentInterval) {
        clearInterval(currentInterval)
      }
    }
  }, [])

  return intervalRef
}

let windowSize = { width: window.innerWidth, height: window.innerHeight }
const sizeListeners = new Set<() => void>()

window.addEventListener(
  'resize',
  debounce(() => {
    windowSize = { width: window.innerWidth, height: window.innerHeight }
    sizeListeners.forEach((l) => l())
  }, 100)
)

export const useWindowSize = () => {
  return useSyncExternalStore(
    (listener) => {
      sizeListeners.add(listener)
      return () => sizeListeners.delete(listener)
    },
    () => windowSize
  )
}

export const useFlyout = () => {
  const dispatch = useAppDispatch()
  const { open, direction, flyoutContent, title, titleIcon, footer } =
    useSelector(selectFlyoutState)
  const { pathname } = useLocation()

  const openFlyout = useCallback(
    (payload: Parameters<typeof setFlyoutContent>[0]) =>
      dispatch(setFlyoutContent(payload)),
    [dispatch]
  )

  const closeFlyout = useCallback(() => {
    dispatch(clearFlyoutContent())
  }, [dispatch])

  useEffect(() => {
    // close flyout on route change
    closeFlyout()
  }, [pathname, closeFlyout])

  return {
    open,
    flyoutContent,
    direction,
    title,
    openFlyout,
    closeFlyout,
    titleIcon,
    footer,
  }
}

export const useSidebar = () => {
  const dispatch = useAppDispatch()
  const { open, sidebarState, scrollYPos } = useSelector(getSidebarState())
  const { pathname } = useLocation()

  const setSidebarState = useCallback(
    (configProps: Record<string, SidebarConfigProps>, defaultOpen = false) => {
      dispatch(
        setReduxSidebarState(
          getDefaultSidebarState(configProps, pathname, defaultOpen)
        )
      )
    },
    [dispatch, pathname]
  )

  const toggleCategory = useCallback(
    (key: string) => {
      dispatch(toggleCategoryOpenClose(key))
    },
    [dispatch]
  )

  const setScrollYState = useCallback(
    (yPos: number) => {
      dispatch(setReduxSidebarScrollYState(yPos))
    },
    [dispatch]
  )

  const toggleSidebar = useCallback(
    (newOpen?: boolean) => {
      dispatch(toggleSidebarOpenClose(newOpen))
    },
    [dispatch]
  )

  return {
    open,
    setSidebarState,
    sidebarState,
    toggleCategory,
    toggleSidebar,
    setScrollYState,
    scrollYPos,
  }
}

export const useReselector = <Args extends unknown[], R>(
  selector: (state: ReduxState, ...params: Args) => R,
  ...params: Args
) => useSelector((state: ReduxState) => selector(state, ...params))

// scrollInnerOnly will only scroll the inner scroll.  If it is false the entire page will scroll
export const useScrollRef = ({
  scrollInnerOnly = false,
  autoScroll = false,
}: { scrollInnerOnly?: boolean; autoScroll?: boolean } = {}) => {
  const scrollRef = useRef<HTMLSpanElement>(null)

  const scrollToRef = useCallback(
    () =>
      scrollRef.current?.scrollIntoView({
        behavior: autoScroll ? 'auto' : 'smooth',
        ...(scrollInnerOnly && { block: 'nearest', inline: 'start' }),
      }),
    [autoScroll, scrollInnerOnly]
  )

  return {
    scrollToRef,
    scrollRef,
  }
}

// Add Survicate to global window namespace
declare global {
  interface Window {
    _sva: {
      setVisitorTraits: (_: { user_id: string }) => void
    }
  }
}

export const useSurvicate = () => {
  const user = useReselector(getCurrentUser)

  const setAttributes = useCallback(() => {
    if (user?.id) {
      window._sva?.setVisitorTraits({
        user_id: user.id.toString(),
      })
    }
  }, [user?.id])

  useEffect(() => {
    const s = document.createElement('script')
    s.src =
      'https://survey.survicate.com/workspaces/d4801e40ea8533048b02c21aeb03a090/web_surveys.js'
    s.async = true
    const e = document.getElementsByTagName('script')[0]
    e.parentNode?.insertBefore(s, e)
  }, [])

  useEffect(() => {
    if (window._sva) {
      setAttributes()
    } else {
      window.addEventListener('SurvicateReady', setAttributes, { once: true })
    }
  }, [setAttributes])
}

export const useNavigateWithPersistParams = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return (to: string, newParams?: { [key: string]: string }) => {
    const params = Object.entries(newParams || {})

    for (const entry of searchParams.entries()) {
      params.push(entry)
    }

    return params.length
      ? navigate(`${to}?${createSearchParams(params)}`)
      : navigate(to)
  }
}

export const useToggle = (initialState = false): [boolean, () => void] => {
  const [state, setState] = useState(initialState)

  const toggle = useCallback(() => {
    setState((prev) => !prev)
  }, [])

  return [state, toggle]
}

// Used as shortcut command for api calls when a component needs the response but it's not saved in the redux state
// Usage `const res = useFetchResponse(fetchCall, 'fetchParam', 'secondParam')`
// Note - This will NOT work if a function is a parameter.  `JSON.stringify` will not pick up those changes
export const useFetchResponse = <T, Args extends unknown[]>(
  apiCall: (...params: Args) => (_: Dispatch, getState: GetState) => Promise<T>,
  ...params: Args
) => {
  const dispatch = useAppDispatch()
  const [res, setRes] = useState<T>()
  const paramString = JSON.stringify(params)

  useEffect(() => {
    const fetch = async () => {
      setRes(await dispatch(apiCall(...params)))
    }

    fetch()
    // If any of the params change you want to refetch but if params are sent in dependencies the array comparison will
    // never work.  Instead the params are stringified and sent in which works but eslint will complain
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCall, dispatch, paramString])

  return res
}

export const useFetchResponseOnInterval = <T, Args extends unknown[]>(
  apiCall: (...params: Args) => (_: Dispatch, getState: GetState) => Promise<T>,
  refetchIntervalInMs: number,
  ...params: Args
) => {
  const dispatch = useAppDispatch()
  const [res, setRes] = useState<T>()
  const paramString = JSON.stringify(params)
  const intervalRef = useIntervalRef()

  useEffect(() => {
    const fetch = async () => {
      setRes(await dispatch(apiCall(...params)))
    }
    // fetch once on mount & then every interval
    fetch()
    intervalRef.current = setInterval(fetch, refetchIntervalInMs)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }

    // If any of the params change you want to refetch but if params are sent in dependencies the array comparison will
    // never work.  Instead the params are stringified and sent in which works but eslint will complain
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCall, dispatch, paramString, refetchIntervalInMs, intervalRef])
  return res
}

export const useMemoizedValue = <T>(initVal: T) => {
  const [val, setVal] = useState(initVal)

  useEffect(() => {
    if (!isEqual(val, initVal)) {
      setVal(initVal)
    }
  }, [initVal, val])

  return val
}

export const useAsyncCallback = <T, A extends unknown[]>(
  sentCallback: (...args: A) => Promise<T>,
  throwError = true
) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const [result, setResult] = useState<T>()

  const callback = useCallback(
    async (...args: A) => {
      setLoading(true)
      try {
        setResult(await sentCallback(...args))
      } catch (err: unknown) {
        const caughtError =
          err instanceof Error
            ? err
            : typeof err === 'string'
              ? new Error(err)
              : new Error('Unknown error')

        setError(caughtError)

        if (throwError) {
          setLoading(false)
          throw caughtError
        }
      } finally {
        setLoading(false)
      }
    },
    [sentCallback, throwError]
  )

  return { loading, result, error, callback }
}

/*
  Get end date logic example: 
  Today is February 5, 2024
  Insights shown through January 31, 2024
  Today is February 1, 2024
  Insights shown through December 31, 2023
  Today is January 11, 2024
  Insights shown through December 31, 2023
  Today is January 04, 2024
  Insights shown through November 30, 2023
*/
export const useGetEndDateForFinancialInsights = (currentDate: DateTime) => {
  const releaseDayOfMonth = 5

  const getEndDate = useCallback(() => {
    let endDate
    if (currentDate.day >= releaseDayOfMonth) {
      endDate = currentDate.minus({ months: 1 }).endOf('month')
    } else {
      endDate = currentDate.minus({ months: 2 }).endOf('month')
    }
    return endDate
  }, [currentDate, releaseDayOfMonth])

  return getEndDate
}

export const useTrackFinancialInsightsButtonClick = () => {
  const track = useAnalyticsTrack()

  const trackInsights = ({
    button,
    insightsType,
  }: {
    button: FinancialInsightsButtonType
    insightsType: INSIGHTS_TYPE
  }) => {
    track(insightsCopyConstants[insightsType].trackButton, { button })
  }

  return trackInsights
}

/**
 * Hook that returns a function that returns whether the component is mounted.
 * @returns true or false
 */
export const useMountedState = (): (() => boolean) => {
  const mountedRef = useRef<boolean>(false)
  const get = useCallback(() => mountedRef.current, [])

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  return get
}

/**
 * Hook that runs an async function on mount and returns the state.
 * It's a shortcut for useAsyncCallback where the function is called on mount.
 *
 * @param fn an async function
 * @returns the state of the async function and the function itself
 */
export const useAsync = <T>(fn: () => Promise<T>) => {
  const state = useAsyncCallback(fn, false)

  useEffect(() => {
    state.callback()
  }, [state.callback]) // eslint-disable-line react-hooks/exhaustive-deps
  // eslint wants state to be in the dependencies but it only depends on the callback

  return { loading: state.loading, result: state.result, error: state.error }
}

/**
 * Hook that returns the value of a flag, but returns null if the flag context is not yet loaded
 */
export const useLoadedFlagValue = <T extends FlagValue>(
  flagName: string,
  defaultVal: T
) => {
  const flag: FlagQuery<
    T extends boolean
      ? boolean | null
      : T extends number
        ? number | null
        : T extends string
          ? string | null
          : T extends JsonValue
            ? T | null
            : JsonValue | null
  > = useFlag(flagName, defaultVal, {
    hooks: [
      {
        after(hookContext, evaluationDetails, _hookHints) {
          if (!hookContext.context.targetingKey) {
            evaluationDetails.value = null
          }
        },
      },
    ],
  })
  return flag.value
}
