import { useEffect } from 'react'
import { Modal, Grid, Image } from 'semantic-ui-react'
import { updateUser } from '../../../actions/userActions'
import {
  Pill,
  GridRowColumn,
  Button,
  Text,
} from '../../../components/BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../Amplitude'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'

const AdvisoryLaunchModal = ({
  currentUserId,
  onClose,
}: {
  currentUserId: number
  onClose?: () => void
}) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const navigate = useNavigate()

  const handleLearnMoreButtonClick = async () => {
    track('clicked financial advisory phase 2 modal button', {
      user_id: currentUserId,
    })
    /* Update user — don't need to do anything after because the returned user will close the modal */
    const data = {
      financialAdvisoryModalViewedAt: DateTime.now().toISO(),
    }
    await updateUser(currentUserId, data)(dispatch)
    navigate('/advisory/overview')
    onClose?.()
  }

  const handleCloseModalButtonClick = async () => {
    track('closed financial advisory phase 2 modal', {
      user_id: currentUserId,
    })
    /* Update user — don't need to do anything after because the returned user will close the modal */
    const data = {
      financialAdvisoryModalViewedAt: DateTime.now().toISO(),
    }
    await updateUser(currentUserId, data)(dispatch)
    onClose?.()
  }

  useEffect(() => {
    track('viewed financial advisory phase 2 modal')
  }, [track])

  return (
    <Modal open size="small" dimmer={'inverted'}>
      <Modal.Header>
        <Pill color="neutral">★ Annual Plan Benefit Only</Pill>
        <br />
        Introducing: Financial Advisory
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn
            columnStyle={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/two_chairs.svg"
              style={{ height: 200 }}
              alt="financial advisory"
            />
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyMd">
              We’re so excited to introduce Financial Advisory Services, in
              partnership with Uprise. Now, with the help of a Certified
              Financial Planner, you can receive a financial plan that is{' '}
              <b>100% customized</b> to your personal financial goals.
            </Text>
            <br />
            <Text as="bodyMd">
              Schedule your free 20 minute Introductory Advisory Call to learn
              how you can accomplish goals like saving for retirement, paying
              back your student loans, purchasing a forever home and much more.{' '}
            </Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions
        style={{
          float: 'right',
          display: 'flex',
          background: 'none',
          border: '0',
        }}
      >
        <Button
          onClick={() => handleCloseModalButtonClick()}
          variant="secondary"
        >
          Close
        </Button>
        <Button onClick={() => handleLearnMoreButtonClick()} variant="primary">
          Learn More
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AdvisoryLaunchModal
