import axios from 'axios'

import { MembershipScopes, User } from '../reducers/auth/userReducer'
import { AsyncReturnType, ReduxState } from './typeHelpers'
import { initialStateSecurityState } from '../reducers/auth/securityReducer'
import { PayrollProfile } from '../features/Payroll/payrollProfile.slice'
import { transformUserToTeamMembers } from '../reducers/taxTeam.slice'
import { datadogRum } from '@datadog/browser-rum'
import { membershipIsIn } from '../selectors/user.selectors'
const usePendo = process.env.VITE_USE_PENDO === 'true'

interface SessionPayload {
  authenticated: boolean
  user: User
}

// Add pendo to global window namespace
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    pendo: pendo.Pendo
  }
}

export const checkLoggedIn = async () => {
  let preloadedState: Partial<ReduxState> | undefined
  const sessionResponse = await axios.get<SessionPayload>('/finances/session')

  const { authenticated, user } = sessionResponse.data
  if (authenticated) {
    datadogRum.setUser({
      id: user.id.toString(),
    })
    // Fetch payroll profile here so sidebar options don't change after load
    const payrollResponse = await axios.get<PayrollProfile>(
      '/finances/api/v1/payroll_profiles'
    )

    preloadedState = {
      auth: {
        user,
        security: {
          ...initialStateSecurityState,
          isAuthenticated: true,
          isAdmin: Boolean(user.admin),
          isFinancesActive: Boolean(user.isFinancesActive),
        },
        rolledOutInstitutions: {
          rolledOutInstitutionIds: [],
        },
      },
      payrollProfile: payrollResponse.data,
      taxTeam: transformUserToTeamMembers(user),
    }

    if (usePendo) {
      const pendo = window.pendo
      const primaryMembership =
        user.memberships?.find((membership) => membership.isPrimary) ?? null
      const isActiveMember = membershipIsIn(
        MembershipScopes.activePayingOrTrial,
        primaryMembership
      )

      const pendoVisitorState = isActiveMember
        ? 'active'
        : (primaryMembership?.status ?? null)
      pendo.initialize({
        visitor: {
          id: `user-${user.uuid}`,
          admin: Boolean(user.admin),
          state: pendoVisitorState,
        },

        /* 
          Pendo Values
        */
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        account: {
          id: `user-${user.uuid}`,
          admin: Boolean(user.admin),
          is_paying: isActiveMember,
          creationDate: user.createdAt,
        },
      })
    }
  }

  return preloadedState
}

export type PreloadedState = AsyncReturnType<typeof checkLoggedIn>
