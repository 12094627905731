import { useCallback, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { Grid, Container, Divider, Message } from 'semantic-ui-react'
import moment from 'moment'

import SignupHeader from '../SignupHeader'
import {
  Button,
  Text,
  GridRowColumn,
  Loader,
} from '../../../components/BaseComponents'
import {
  FREE_TRIAL_DAYS,
  SIGNUP_PATHS,
  canOptIntoPreviousYearTaxes,
} from '../helpers'
import {
  useNavigateWithPersistParams,
  useReselector,
  useScrollRef,
} from '../../../utils/sharedHooks'
import {
  FETCH_STRIPE_PRODUCTS_KEY,
  StripeProduct,
  fetchCheckoutSession,
  fetchEligibleStripeProducts,
  FETCH_CHECKOUT_SESSION_KEY,
  StripeLineItem,
} from '../../../actions/settings/billingActions'
import {
  sendOnboardingZap,
  sendUserCRMCreationZap,
} from '../../../actions/zapierActions'
import {
  getCurrentUser,
  getFreeTrialStatus,
  selectIsFreeTrialEnabled,
} from '../../../selectors/user.selectors'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { useAnalyticsView } from '../../Amplitude'
import { TrialStatus } from '../../../reducers/auth/userReducer'
import {
  getFetchError,
  getIsFetching,
  getIsFetchingOrNotStarted,
} from '../../../reducers/fetch'
import StripeProductCard from './StripeProductCard'
import { fetchBasicProductsV2 } from './helpers'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { getAnnualTaxFilingForYearSelector } from '../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchAnnualTaxFilingsIfNeeded } from '../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useCatchupBookkeepingPrice } from './hooks'

const ChoosePlan = () => {
  const navigate = useNavigateWithPersistParams()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [stripeProducts, setStripeProducts] = useState<StripeProduct[]>()
  const user = useReselector(getCurrentUser)
  const { scrollRef, scrollToRef } = useScrollRef({ autoScroll: true })
  const loading = useReselector(getIsFetching, FETCH_CHECKOUT_SESSION_KEY)

  const [selectedProduct, setSelectedProduct] = useState<StripeProduct>()
  const [searchParams] = useSearchParams()

  const basicPlanEnabled = searchParams.get('subscription') === 'new'
  const catchupBookkeepingPrice = useCatchupBookkeepingPrice()

  /* Free-trial related */
  // e.g. active, inactive, unredeemed
  const userFreeTrialStatus = useReselector(getFreeTrialStatus)

  // Based on user's current & past activity, should we enable the trial
  const freeTrialSearchParamValue = searchParams.get('trial')
  const freeTrialEnabled = useReselector(
    selectIsFreeTrialEnabled,
    freeTrialSearchParamValue,
    basicPlanEnabled
  )

  useSignupPageUpdate(SIGNUP_PATHS.choosePlan)

  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('sign up choose plan')
  }, [pageView])

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
  }, [dispatch])

  const fetchingStripeProducts =
    useReselector(getIsFetchingOrNotStarted, FETCH_STRIPE_PRODUCTS_KEY) &&
    !basicPlanEnabled

  const fetchingStripeProductsError = useReselector(
    getFetchError,
    FETCH_STRIPE_PRODUCTS_KEY
  )
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const taxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )

  useEffect(() => {
    async function performAsync() {
      if (basicPlanEnabled) {
        const basicProducts = fetchBasicProductsV2()
        const pertinentStripeProducts =
          user?.financialProfile?.practiceType === 'group'
            ? basicProducts.BASIC_GROUP_PRODUCTS
            : basicProducts.BASIC_SOLO_PRODUCTS

        setStripeProducts(pertinentStripeProducts)
      } else {
        const data = await fetchEligibleStripeProducts()(dispatch)
        // Sort annual products first (currently we only expect 2 products - monthly & annual)
        if (data && data.length > 1) {
          data.sort(
            (a, b) =>
              -a.default_price.recurring.interval.localeCompare(
                b.default_price.recurring.interval
              )
          )
        }
        setStripeProducts(data)
      }
    }

    performAsync()
  }, [dispatch, basicPlanEnabled, user?.financialProfile?.practiceType])

  const navToPayment = useCallback(async () => {
    if (!user?.id || !selectedProduct?.default_price.id) {
      return
    }

    const lineItems: StripeLineItem[] = [
      {
        priceId: selectedProduct.default_price.id,
        quantity: 1,
      },
    ]
    const metadata: Record<string, string> = {}

    if (catchupBookkeepingPrice?.priceId) {
      lineItems.push({
        priceId: freeTrialEnabled
          ? catchupBookkeepingPrice.freeTrialPriceId
          : catchupBookkeepingPrice.priceId,
        quantity: catchupBookkeepingPrice.monthCount,
      })

      if (freeTrialEnabled) {
        metadata.catchupBkPriceId = catchupBookkeepingPrice?.priceId
        metadata.catchupBkQuantity =
          catchupBookkeepingPrice?.monthCount?.toString()
      }
    }

    const res = await fetchCheckoutSession({
      userId: user?.id,
      lineItems,
      success_url: SIGNUP_PATHS.success,
      // Navigate back to current page with query params
      cancel_url: `${location.pathname}${location.search}`,
      freeTrialEnabled,
      freeTrialSearchParamValue,
      metadata,
    })(dispatch)
    if (res) {
      window.location.href = res.url

      sendUserCRMCreationZap({
        createdAt: user.createdAt,
        email: user.email,
        financialProfile: user.financialProfile,
        firstName: user.firstName,
        lastName: user.lastName,
      })

      sendOnboardingZap({
        email: user.email,
        screenName: SIGNUP_PATHS.choosePlan,
      })()
    }
  }, [
    user?.id,
    user?.createdAt,
    user?.email,
    user?.financialProfile,
    user?.firstName,
    user?.lastName,
    selectedProduct?.default_price.id,
    catchupBookkeepingPrice?.priceId,
    catchupBookkeepingPrice?.monthCount,
    location.pathname,
    location.search,
    freeTrialEnabled,
    freeTrialSearchParamValue,
    dispatch,
    catchupBookkeepingPrice?.freeTrialPriceId,
  ])

  const trialEndDate = moment()
    .add(FREE_TRIAL_DAYS, 'days')
    .format(DATE_FORMATS.DISPLAY_SIMPLE)
  // Used to toggle highlight of sign up header dependent on whether we show late sign up screen
  const currentStep = canOptIntoPreviousYearTaxes() ? 4 : 3
  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid stackable>
        <span ref={scrollRef} />
        <SignupHeader currentStep={currentStep} />
        <GridRowColumn>
          <Text as="h1">
            Choose to pay annually or monthly, whatever meets your needs.
          </Text>
        </GridRowColumn>
        {userFreeTrialStatus === TrialStatus.inactive && (
          <GridRowColumn>
            <Message
              warning
              content={
                <Text>
                  Your {FREE_TRIAL_DAYS}-day free trial has ended. Please choose
                  a plan and complete payment to access your heard account.
                </Text>
              }
            />
          </GridRowColumn>
        )}
        {userFreeTrialStatus !== TrialStatus.inactive && (
          <GridRowColumn short>
            <Text as="bodyLg">
              {freeTrialEnabled
                ? `Try Heard for the first ${FREE_TRIAL_DAYS} days before getting billed. You won't be charged until your trial ends on ${trialEndDate}. Cancel anytime.`
                : 'Based on your answers, the plan below fits your practice.'}
            </Text>
          </GridRowColumn>
        )}
        {taxFiling?.isLateJoiner && (
          <GridRowColumn short>
            <Text as="bodyLg">
              Because you&#39;ve indicated that you would like to opt into{' '}
              {currentTaxYear} taxes, this would require an annual commitment.
            </Text>
          </GridRowColumn>
        )}
        <GridRowColumn>
          {/* Fetching products */}
          <Loader
            fetchKey={FETCH_STRIPE_PRODUCTS_KEY}
            loading={fetchingStripeProducts}
          />
          {/* Error fetching products */}
          {fetchingStripeProductsError && (
            <Message
              info
              header="Missing information"
              content={<Text>{fetchingStripeProductsError.message}</Text>}
            />
          )}
          {!fetchingStripeProducts && stripeProducts && (
            <StripeProductCard
              stripeProducts={stripeProducts}
              selectedProduct={selectedProduct}
              onSelect={setSelectedProduct}
              freeTrialEnabled={freeTrialEnabled}
              basicPlanEnabled={basicPlanEnabled}
              isLateJoiner={taxFiling?.isLateJoiner}
            />
          )}

          {/* No products found - in the event products aren't properly configured on Stripe.com */}
          {!fetchingStripeProducts &&
            !fetchingStripeProductsError &&
            !stripeProducts && (
              <Text>
                We could not find any matching Products for you. Please email
                contact@joinheard.com for further assistance.
              </Text>
            )}
        </GridRowColumn>
        <GridRowColumn>
          <Divider />
        </GridRowColumn>

        <Grid.Row>
          <Grid.Column width={2}>
            <Button
              variant="secondary"
              onClick={() =>
                navigate(
                  canOptIntoPreviousYearTaxes()
                    ? SIGNUP_PATHS.previousYearTax
                    : SIGNUP_PATHS.practiceJourney
                )
              }
            >
              Back
            </Button>
          </Grid.Column>
          <Grid.Column width={12} />
          <Grid.Column width={2}>
            {selectedProduct && (
              <Button
                onClick={navToPayment}
                disabled={loading}
                loading={loading}
              >
                Continue
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default ChoosePlan
