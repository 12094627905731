import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUpriseSession } from './financialAdvisory.slice'
import { Loader } from '../../components/BaseComponents'
import {
  UPRISE_INBOX_EVENT_CODE,
  UPRISE_SESSION_EXPIRED_EVENT_CODE,
} from './service'
import { useNavigate } from 'react-router-dom'
import { track } from '@amplitude/analytics-browser'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'

/**
 * Used to handle Uprise Iframe (embedded flow)
 *
 * Listens to the following events
 * session_expired: This postMessage event will be posted to Heard when token expired
 * launch_inbox: This postMessage event will be posted to Heard when customer click on ‘message advisor’ link.
 *
 */
export const UpriseIframe = ({ page }: { page: string }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetching, setFetching] = useState(false)
  const [upriseSessionId, setUpriseSessionId] = useState(null)
  const [refreshSessionId, setRefreshSessionId] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const currentUser = useReselector(getCurrentUser)

  const UPRISE_HOST = process.env.VITE_UPRISE_HOST_NAME

  useEffect(() => {
    async function fetchData() {
      setFetching(true)
      const sessionResponse = await dispatch(getUpriseSession())
      setUpriseSessionId(sessionResponse.sessionId)
      setFetching(false)
    }
    fetchData()
  }, [dispatch, refreshSessionId])

  useEffect(() => {
    track('uprse session started', {
      page,
      user_id: currentUser?.id,
    })
  }, [currentUser?.id, page])

  // Listen to postMessage event from Uprise
  useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      if (event?.origin === UPRISE_HOST) {
        if (event.data?.eventCode === UPRISE_INBOX_EVENT_CODE) {
          // Navigate to Messages
          navigate('/advisory/messages')
        } else if (
          event.data?.eventCode === UPRISE_SESSION_EXPIRED_EVENT_CODE
        ) {
          // Referesh SessionId
          setRefreshSessionId(true)
        }
      }
    }
    window.addEventListener('message', handleMessageEvent)
    return () => {
      window.removeEventListener('message', handleMessageEvent)
    }
  }, [UPRISE_HOST, navigate])

  if (fetching || !upriseSessionId) {
    return <Loader loading={fetching} />
  } else {
    return (
      <div>
        <iframe
          title="Financial Advisory"
          style={{
            border: '0',
            height: '100vh',
          }}
          onLoad={() => {
            iframeRef.current?.contentWindow?.postMessage(
              JSON.stringify({
                uSid: upriseSessionId,
                uPge: page,
              }),
              `${UPRISE_HOST}`
            )
          }}
          ref={iframeRef}
          src={`${UPRISE_HOST}/auth-access`}
          width="100%"
          height="800px"
        />
      </div>
    )
  }
}
